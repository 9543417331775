.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;

}

.ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    margin-right: -50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 400px;
    max-width: 450px;
}

.ReactModal__Content h2 {
    text-align: center;
    margin: 0 0 20px;
    font-size: 24px;
    color: #333;
}

.ReactModal__Content form {
    display: flex;
    flex-direction: column;
}

.ReactModal__Content input {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 400px;
    box-sizing: border-box;
}

.ReactModal__Content .error-message {
    color: red;
    font-size: 12px;
    margin: 5px 0;
}

.login-cancle-btn{
  display: flex;
    align-items: center;
    justify-content: space-between;

}

.ReactModal__Content button {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    float: right;
}


.ReactModal__Content button.close-button {
    background-color: #c01349;
}

.ReactModal__Content button.close-button:hover {
    background-color: #5a6268;
}

.password-reset-link{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: auto;
    

}

.password-reset-link button {
    background: none;
    color: black;
    border: none;
    cursor: pointer;
    color: #0056b3;
    margin-top: 10px;
}

.password-reset-link button:hover {
    text-decoration: underline;
}

/* passowrd rest ui css */

.password-rest-p{
    text-align: center;
    margin-top: 20px;
    color: red;
    font-weight: 600;

}

.password-rest-form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-reset-btn{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.message{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    color: #232425;
    font-weight: bold;
    background-color: aqua;
    min-width: 300px;
    width: auto;
}

.error-message-reset{
    margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        color: white;
        font-weight: bold;
        background-color: rgb(181, 53, 53);
        min-width: 300px;
        width: auto;
}