/* UserForm.css */

.body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f2f5;
}


.user-form {
    text-align: center;
    margin-bottom: 20px;
}

.user-form p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.user-creaet-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: #0056b3 2px solid;
    width: 400px;
}

.password-create-form{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: #0056b3 2px solid;
    width: 400px;
}

/* for mobile app */

@media (max-width: 768px) {
    .user-creaet-form {
        width: 100%;
    }
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}


.form-group button:hover {
    background-color: #0056b3;
}

.message{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    color: #232425;
    font-weight: bold;
    background-color: aqua;
    min-width: 300px;
    width: auto;
}

.error-message{
    margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        color: rgb(222, 210, 188);
        font-weight: bold;
        background-color: rgb(181, 53, 53);
        min-width: 300px;
        width: auto;
}

.submit-button{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}