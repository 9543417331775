.user-invitation-form form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 5px;
    border-bottom: solid 3px #879bb0;
}

.user-invitation-form form label {
    margin-right: 10px;
    font-size: 20px;
    font-weight: 600;

}

/* in tablate */
@media (max-width: 768px) {
    .user-invitation-form form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        padding: 20px;
        border-radius: 5px;
        flex-direction: column;
    }
}

/* in mobile */
@media (max-width: 480px) {
    .user-invitation-form form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        padding: 20px;
        border-radius: 5px;
        flex-direction: column;
    }
}

.user-invitation-form input{
    width: 300px;
    height: 40px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
}

.user-invitation-form select{
    width: 300px;
    height: 40px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size:18px;
    font-weight: 600;
}

.user-invitation-form button{
    width: 300px;
    height: 40px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #4e83a1;
    color: white;
    cursor: pointer;
}