* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0;
    background-color: #236565;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    height: 67px;
    position: fixed;
    top: 0;
    left: 0;
}

.logo h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    padding-left: 20px;
}

.nav {
     padding-right: 40px;
}

.nav-admin{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding-right: 20px;
    height: 50px;
    width: auto;

}

.navbar-links {
    display: flex;
    justify-content: center;
    padding-right: 20px;
    height: 50px;
    width: auto;
}
.navbar-links ul{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 100%;
    height: 100%;


}
.navbar-links li .logout-button{
    background:transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    padding: 0 10px;
    border-radius: 10px;
    color: white !important;
    font-weight: 600;
    font-size: 20px;
    border: solid 1px black;
}

.navbar-links a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 25px;
    color: #fff;
    padding: 0 10px;
    transition: color 0.3s ease;
    border-radius: 10px;
    border: solid 1px black;
}

.navbar-links a:hover {
    color: #ffdd57;
}

.popup-open-bnt{
    background: transparent;
    border: solid 1px rgb(175, 159, 159);
    padding: 10px;
    border-radius: 10px;
}

.navigation-layout{
    margin-top: 72px;
    max-width: 100%;
    padding: 0 40px;
    overflow: hidden;
}

.admin-links{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding-right: 20px;
    height: 50px;
    font-weight: bold;
    font-size: 20px;
    width: auto;

}

button{
    margin-left: 20px;
    border: none;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding-right: 20px;
    height: 50px;
    font-weight: bold;
    font-size: 20px;
    width: auto;
}


/* for mobile screen */
@media only screen and (max-width: 600px) {
    
    .logo h1 {
        font-size: 18px;
    }

    .navbar {
        height: 50px;
    }

    .navbar-links a{
        font-size: 14px;
    }

    .navigation-layout{
        margin-top: 60px;
        padding: 0 1px;
    }
}