.button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    width: auto;
    margin-right: 10px;
    border-radius: 10px;
}