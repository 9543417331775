
.search-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-input{
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    width: 150px;
    height: 45px;

}

.select-style{
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    width: 150px;
    height: 45px;
}


.search-btn{
    margin-left: 10px;
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 2px;
    border: none;
    width: 100px;
    height: 45px;
    background-color: #007bff;
    color: white;
}

/* mobile screen */

@media only screen and (max-width: 600px) {

    .search-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .search-input{
        width: auto;
    }

    .select-style{
        width: 100px;
    }

    .search-btn{
        width: 80px;
    }
}