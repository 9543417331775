.thread-details-header{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    border-bottom: 2px solid #b5b0b0;
    border-radius: 10px;
}
.thread-details-header-content h2  {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000;

}

.thread-details-header-details{
    margin-top: 5px;
    display: flex;
    gap: 20px;

}

.thread-details-header-details p{
    font-size: 0.9rem;
    color: #000;
    font-weight: 500;
}

/* mobile ui */
@media only screen and (max-width: 600px) {
    .thread-details-header{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .thread-details-header-content h2{
        font-size: 1rem;
    }

    .thread-details-header-details p{
        font-size: 0.8rem;
    }
}