.post-form {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid rgb(65, 43, 207);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
}

.post-username-email-id {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.post-username-email-id input {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.post-username-email-id input:last-child {
    margin-right: 0;
}

.post-body {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    min-height: 80px;
    resize: vertical;
    font-size: 14px;
}

.file-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.file-input input {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}


.select-image {
    width: 100%;
    min-height: auto;
    max-height: 300px;
    overflow: hidden;
}

.select-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin-top: 10px;
    border-radius: 10px;
}


.post-submit {
    display: inline-block;
    background-color: #5566A1;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
}

.post-submit:hover {
    background-color: #3b4d8a;
}

.clear-image {
    display: inline-block;
    background: transparent;
    padding: 10px 20px;
    color: red;
}
.clear-image:hover {
    background-color: #99bcbc;
}

/* mobile ui */
@media only screen and (max-width: 600px) {
    .post-form {
        width: 90%;
        padding: 10px;
        max-height: calc(100vh - 40px);
    }
    .post-username-email-id {
        flex-direction: column;
        width: 100%;
    }

    .post-username-email-id input {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .post-username-email-id input:last-child {
        margin-bottom: 0;
    }

    .post-body {
        width: 90%;
    }

    .file-input {
        flex-direction: column;
        align-items: flex-start;
    }

    .file-input input {
        margin-bottom: 10px;
        width: 100%;
    }

    .file-input img {
        margin-left: 0;
        margin-top: 10px;
    }
}
