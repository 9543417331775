.list {
    display: flex;
    align-items: center;
    padding: 10px;
    flex-direction: column;
  }
  
  .list-content {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: solid 1px rgb(193, 188, 188);
    border-radius: 20px;
    flex-direction: row;
  }
  
  .list-content-title {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .list-content-title h2 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
    word-wrap: break-word; 
    max-width: 100%; 
  }
  
  .list-content-title p {
    margin: 0;
    color: #888;
    font-size: 0.9em;
  }
  
  .list-content-title a {
    color: black;
    text-decoration: none;
  }
  
  .list-content-title a:hover {
    text-decoration: underline;
  }
  
  .list-content-details {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .delete-btn {
    border: solid 1px red;
    height: 40px;
    width: 40px;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Media Query for Mobile Phones */
  @media only screen and (max-width: 600px) {
    .list {
      height: auto;
    }
  
    .list-content {
      align-items: flex-start;
      gap: 10px;
    }
  
    .list-content-icon {
      height: 25px;
      width: 25px;
      padding: 5px;
      margin-bottom: 10px;
    }
  
    .list-content-title h2 {
      font-size: 1em;
    }
  
    .list-content-details {
      flex-direction: column;
      gap: 5px;
    }
  
    .delete-btn {
      height: 25px;
      width: 25px;
      padding: 5px;
    }
    .deleet-icon{
      margin-right: 30px;
    }
  }
  