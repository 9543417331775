.post-body{
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
    padding: 10px;
    margin-left: 50px;
    border-bottom: solid #b3aeae 1px;
}

.comment-posts{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    margin-left: 50px;

}

.comment-post{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    margin-left: 70px;
    border-bottom: solid #b3aeae 1px;
}

.comment-posts .comment-post .comment-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}