.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    background-color: #f4f4f4;
    text-align: center;
    flex-direction: column;
}

.not-found h2 {
    font-size: 5rem;
    margin-bottom: 1rem;
}

.not-found p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.not-found a {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
    border: 1px solid #333;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}