.post {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  margin-left: 50px;
  border-bottom: solid #b3aeae 1px;
}

.post-id-delete {
  display: flex;
  gap: 10px;
}

.post .parent-post-id {
  font-size: 18px;
  font-weight: 600;
  color: blueviolet;
}

.post-id {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-id p {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 17px;
}

.post-content {
  flex-grow: 1;
  white-space: pre-wrap;
}

.post-content p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
}

.post-date-email {
  display: flex;
  gap: 10px;
}

.post-date-email small {
  font-size: 14px;
  color: #8e8989;
}

.post-date-email P {
  font-size: 14px;
  color: #050303;
}
.delete-button {
  height: 40px;
  width: 40px;
  margin-right: 20px;
  padding: 5px 10px;
  border-radius: 150px;
  cursor: pointer;
  background: none;
  border: 1px solid red;
}

.post-image {
  width: 100%;
  min-height: auto;
  max-height: 350px;
  overflow: hidden;
  margin-bottom: 10px;
}

.post-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin-top: 10px;
  border-radius: 10px;
}

/* mobile ui */
@media only screen and (max-width: 600px) {
  .post {
    margin-left: 0;
    gap: none;
    padding: 1px;
  }

  .post-id-delete {
    display: flex;
    flex-direction: row;
    gap: 1px;
  }

  .post-id {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post-id p {
    font-size: 10px;
  }
  .post-content h2 {
    font-size: 18px;
  }
  .post-date-email small {
    font-size: 12px;
  }
  .post-date-email p {
    font-size: 12px;
  }
  .delete-button {
    height: 25px;
    width: 25px;
  }
}
