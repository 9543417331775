.thread-list-container {
    display: flex;
    flex-direction: column;
}

.thread-list {
    overflow-y: auto;
    flex-grow: 1;
}

.thread-list::-webkit-scrollbar {
    width: 8px;
}

.thread-list::-webkit-scrollbar-thumb {
    background-color: #888;
}