.password-reset-conf {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 90vh;
    background-color: #f0f2f5;
  }
  
  .password-reset-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: #0056b3 2px solid;
    width: 400px;
    max-width: 90%;
  }

  .password-reset-form h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .password-reset-form input {
    width: calc(100% - 20px); 
    padding: 10px;
    margin: 5px 0 15px 0;
    border: #0056b3 1px solid;
    border-radius: 5px;
  }

  .password-send-btn{
    background-color: #0056b3;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .message{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    color: #232425;
    font-weight: bold;
    background-color: aqua;
    min-width: 300px;
    width: auto;
}

.error-message{
    margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        color: rgb(222, 210, 188);
        font-weight: bold;
        background-color: rgb(181, 53, 53);
        min-width: 300px;
        width: auto;
}
  