.verify-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #f0f2f5;
  }
  
  .verify-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: white;
  }
  
  .resend-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    text-align: center;
  }
  .resend-container p{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  
  .resend-group {
    margin-bottom: 10px;
  }
  
  .resend-group label {
    display: block;
    font-weight: bold;
  }
  
  .resend-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .resend-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .resend-btn:hover {
    background-color: #0056b3;
  }
  