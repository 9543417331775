.list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(219, 228, 231) !important;
    border-radius: 5px;
    padding: 10px;
  }
  
  .list-header-title {
    font-size: 25px;
    font-weight: bold;
    flex: 1;
  }

  
  .list-header-search {
    flex: 2;
    display: flex;
    justify-content: center;
  }
  
  .thread-button {
    background-color: #16598c;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* for mobile phone */
  @media only screen and (max-width: 600px) {
    .list-header {
      flex-direction: column;
      align-items: center;
    }
  
    .list-header-title {
      display: none;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .list-header-search {
      flex: 1;
      justify-content: flex-start;
      margin-top: 10px;
      width: 100%;
    }
  
    .thread-button {
      padding: 5px 15px;
      font-size: 12px;
      margin-top: 10px;
      margin-left: 10px;
      flex: 0;
    }
  }
  